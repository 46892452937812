import vue from "vue";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import FormGroup from "@/components/FormGroup";
import * as rules from "vee-validate/dist/rules";

import i18n from "@/lang";

vue.component("ValidationProvider", ValidationProvider);
vue.component("ValidationObserver", ValidationObserver);
vue.component("FormGroup", FormGroup);
//messages for all rules
const customMessages = {
  getMessage(_, values) {
    let mimesRule;
    if (typeof values == "object") {
      const spreadData = (({ _field_, _value_, _rule_, ...values }) => values)(
        values
      );
      mimesRule = Object.values(spreadData).join(",");
    }

    // "englishOnly" custom message
    if (values._rule_ === "englishOnly") {
      return i18n.t("validation.englishOnly", {
        field: i18n.t(`fields.${values._field_}`)
      });
    }

    return i18n.t(`validation.${values._rule_}`, {
      field: i18n.t(`fields.${values._field_}`),
      max: values.length,
      min: values.length,
      max_value: values.max,
      min_value: values.min,
      comparedField: i18n.t(`fields.${values.comparedField}`),
      mimes: mimesRule,
      size : values.size
    });
  }
};

// extend("phoneOrEmail", phoneOrEmailRule);
//All rules
Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule],
    message: customMessages.getMessage
  });
});


// initialize "englishOnly" rule
extend("englishOnly", {
  validate(value) {
    const pattern = /^[a-zA-Z]+$/;
    return pattern.test(value);
  },
  message: customMessages.getMessage
});