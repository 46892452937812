import Vue from "vue";
import Router from "vue-router";
import Layout from "@/layout";
import cookie from "js-cookie";
import store from "@/store";
import { asyncRoutes } from "./asyncRoutes";
Vue.use(Router);
//Catching errors in push replace
//Of course, errors in replace can be captured the same way
const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;
// push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
// replace
Router.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};
// Base Routes
export const constantRouterMap = [
  {
    path: "/",
    component: Layout,
    name: "layout",
    props: true,
    meta: {
      title: "home"
    },
    children: [
      {
        path: "/home",
        component: () => import("@/views/home"),
        name: "home",
        meta: {
          icon: "mdi-home-variant-outline",
          title: "home"
          // permissions: "home.index",
        }
      },
      {
        path: "/profile",
        component: () => import("@/views/profile/index.vue"),
        name: "profile",
        meta: {
          title: "profile"
          // permissions: "home.index",
        }
      },

      ...asyncRoutes
    ]
  },
  {
    path: "/login",
    component: () => import("@/views/login/index"),
    meta: {
      title: "login"
    },
    hidden: true
  },
  // {
  //   path: "/reset",
  //   component: () => import("@/views/resetPass/index"),
  //   meta: {
  //     title: "reset"
  //   },
  //   hidden: true
  // },
  // {
  //   path: "/verification-method",
  //   component: () => import("@/views/verificationMethods/index"),
  //   meta: {
  //     title: "verification-method"
  //   },
  //   hidden: true
  // },
  // {
  //   path: "/verify-code",
  //   component: () => import("@/views/verficationCode/index"),
  //   meta: {
  //     title: "verify-code"
  //   },
  //   hidden: true
  // },
  {
    path: "/401",
    component: () => import("@/views/errorPage/401"),
    name: "Page401",
    meta: {
      title: "Page401"
    },
    hidden: true
  },
  {
    path: "/not-found",
    component: () => import("@/views/errorPage/404"),
    name: "404",
    meta: {
      title: "404"
    },
    hidden: true
  },
  {
    path: "*",
    component: () => import("@/views/errorPage/404"),
    name: "Page404",
    meta: {
      title: "Page404"
    },
    hidden: true
  }
];
const router = new Router({
  mode: "history",
  //   base: process.env.BASE_URL,
  routes: constantRouterMap
});
router.beforeEach((to, from, next) => {
  if (cookie.get("token")) {
    if (
      to.path === "/login" ||
      to.path === "/verify-code" ||
      to.path === "/verification-method" ||
      to.path === "/reset"
    ) {
      next("/home");
    } else {
    
      next();
    }
  } else {
    if (
      to.path === "/login" ||
      to.path === "/verify-code" ||
      to.path === "/verification-method" ||
      to.path === "/reset" ||
      to.path === "/employees/complete_register"
    ) {
      next();
    } else {
      next("/login");
    }
  }
});

export default router;
