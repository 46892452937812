import Vue from "vue";
import Vuex from "vuex";
import errors from "./modules/errors";
import notification from "./modules/notification";
import user from "./modules/user";
import query from "./modules/query";
import routes from "./modules/routes";
import tables from "./modules/tables";
import themes from "./modules/themes";
import locale from "./modules/locale";
import company from './modules/company'
import getters from "./getters";
import countries from './modules/countries'

Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: false,
  modules: {
    errors,
    notification,
    user,
    query,
    routes,
    tables,
    themes,
    locale,
    company,
    countries
  },
  getters: getters
});
