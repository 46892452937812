export const statementsRoutes = {
  path: "/daily-statements",
  to: "/daily-statements",
  icon: "mdi-account-multiple-outline",
  activeRoutes: ["record_daily-statements", "company-daily-statements"],
  allow_children: false,
  showInMenu: true,
  name: "daily-statements",
  meta: {
    title: "daily-statements",
    permissions: "daily-statements",
  },
  order: 17,
  component: () => import("@/views/daily-statements"),
  children: [
    {
      path: "/",
      component: () => import("@/views/daily-statements/record"),
      name: "record_daily-statements",
      icon: "mdi-file-document-multiple-outline",
      to: "/daily-statements/record",
      allowed: true,
      meta: {
        title: "record_daily-statements",
        permissions: "daily-statements",
      },
    },

    {
      path: ":id/company-daily-statements",
      component: () =>
        import("@/views/daily-statements/view/company-daily-statements"),
      name: "company-daily-statements",
      icon: "mdi-square-show-outline",
      to: "/daily-statements/view/company-daily-statements",

      hidden: true,
      meta: {
        title: "company-daily-statements",
        permissions: "daily-statements/company-daily-statements",
      },
      children: [
        {
          path: "/",
          component: () =>
            import(
              "@/views/daily-statements/view/company-daily-statements/record"
            ),
          name: "record_company-daily-statements",
          icon: "mdi-file-document-multiple-outline",
          to: "/daily-statements/view/company-daily-statements/record",
          allowed: true,
          meta: {
            title: "record_daily-statements",
            permissions: "daily-statements",
          },
        },

        {
          path: ":id",
          component: () =>
            import(
              "@/views/daily-statements/view/company-daily-statements/view/index.vue"
            ),
          name: "company-daily-statements_view",
          icon: "mdi-square-show-outline",
          hidden: true,
          meta: {
            title: "company-daily-statements_view",
            permissions: "daily-statements/company-daily-statements",
          },
        },
      ],
    },
  ],
};
