
import { asyncSettingRoutes } from "@/router/settingsRoutes"

export const settingsRoutes = {
  path: "/settings",
  to: "/settings",
  icon: "mdi-cog-outline",
  activeRoutes: ["record_settings", "edit_setting"],
  meta: {
    title: "settings",
    permissions: "settings"
  },
  showInMenu: true,
  name: "settings",
  allow_children: false,
  order: 9,

  component: () => import("@/views/new-settings"),
  children: [
    {
      path: "/",
      component: () => import("@/layout/settingLayout"),
      name: "record_setting",
        icon: "mdi-square-edit-outline",
        hidden: true,
        meta: {
          title: "record_setting",
          permissions: "record_edit"

      },
    },
    {
      path: "main_informations",
      component: () => import("@/layout/settingLayout"),
      icon: "arrow.svg", // < arrow icon
      to: "/new-settings/main_informations",
      allowed: true,
      meta: {
        title: "all_settings",
        permissions: "settings_view",
      },
      children: [
        ...asyncSettingRoutes
      ]
    }

    // {
    //   path: ":id/edit",
    //   component: () => import("@/views/settings/actions"),
    //   name: "edit_setting",
    //   icon: "mdi-square-edit-outline",
    //   hidden: true,
    //   meta: {
    //     title: "edit_setting",
    //     permissions: "settings_edit"
    //   }
    // }
  ]
};


// export const settingsRoutes = {
//   path: "/settings",
//   to: "/settings",
//   icon: "mdi-cog-outline",
//   activeRoutes: ["record_settings", "edit_setting"],
//   meta: {
//     title: "settings",
//     permissions: "settings"
//   },
//   showInMenu: true,
//   name: "settings",
//   allow_children: false,
//   order:11,
//   component: () => import("@/views/settings"),
//   children: [
//     {
//       path: "/",
//       component: () => import("@/views/settings/record"),
//       name: "record_settings",
//       icon: "mdi-file-document-multiple-outline",
//       to: "/settings/record",
//       allowed: true,
//       meta: {
//         title: "record_settings",
//         permissions: "settings"

//       }
//     },

//     {
//       path: ":id/edit",
//       component: () => import("@/views/settings/actions"),
//       name: "edit_setting",
//       icon: "mdi-square-edit-outline",
//       hidden: true,
//       meta: {
//         title: "edit_setting"
//       }
//     }
//   ]
// };
