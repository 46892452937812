export const reportsRoutes = {
  path: "/daily-online-reports",
  to: "/daily-online-reports",
  icon: "mdi-account-multiple-outline",
  activeRoutes: ["record_daily-online-reports", "company-daily-online-reports"],
  allow_children: false,
  showInMenu: true,
  name: "daily-online-reports",
  meta: {
    title: "daily-online-reports",
    permissions: "daily-online-reports",
  },
  order: 18,
  component: () => import("@/views/daily-online-reports"),
  children: [
    {
      path: "/",
      component: () => import("@/views/daily-online-reports/record"),
      name: "record_daily-online-reports",
      icon: "mdi-file-document-multiple-outline",
      to: "/daily-online-reports/record",
      allowed: true,
      meta: {
        title: "record_daily-online-reports",
        permissions: "daily-online-reports",
      },
    },

    {
      path: ":id/company-daily-online-reports",
      component: () =>
        import(
          "@/views/daily-online-reports/view/company-daily-online-reports"
        ),
      name: "company-daily-online-reports",
      icon: "mdi-square-show-outline",
      to: "/daily-online-reports/view/company-daily-online-reports",

      hidden: true,
      meta: {
        title: "company-daily-online-reports",
        permissions: "daily-online-reports/company-daily-online-reports",
      },
      children: [
        {
          path: "/",
          component: () =>
            import(
              "@/views/daily-online-reports/view/company-daily-online-reports/record"
            ),
          name: "record_company-daily-online-reports",
          icon: "mdi-file-document-multiple-outline",
          to: "/daily-online-reports/view/company-daily-online-reports/record",
          allowed: true,
          meta: {
            title: "record_daily-online-reports",
            permissions: "daily-online-reports",
          },
        },

        {
          path: ":id",
          component: () =>
            import(
              "@/views/daily-online-reports/view/company-daily-online-reports/view/index.vue"
            ),
          name: "company-daily-online-reports_view",
          icon: "mdi-square-show-outline",
          hidden: true,
          meta: {
            title: "company-daily-online-reports_view",
            permissions: "daily-online-reports/company-daily-online-reports",
          },
        },
      ],
    },
  ],
};
