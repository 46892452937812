import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "@/lang";
import "./plugins/vee-validate";
import "./bootstrap";
import Cookies from "js-cookie";


Vue.config.productionTip = false;
new Vue({
  store,
  router,
  vuetify,
  i18n,
  created() {
    if (Cookies.get("token")) {

      store.dispatch("GetUserInfo").then(() => {
        let allowedRoutes = store.getters.getRoutes.reduce((acc, route) => {
          acc.push(...route.children);
          return acc;
        }, []);
        console.log(allowedRoutes.map((route) => route.path));
        let currentRoute = router.currentRoute;
        //# if the current route is not in the allowed routes, redirect to the first allowed route
        //if the current route from sub companies pages [company layout]
        console.log(currentRoute);
        if (currentRoute.meta.permissions?.includes("company") && !currentRoute.meta.permissions?.includes("company-edits")) {
          let allowedCompanyRoutes = store.getters.getCompanyRoutes;
          if (
            !allowedCompanyRoutes.some(
              (route) =>
                route.meta.permissions === currentRoute.meta.permissions
            ) &&
            currentRoute.name !== "Page404"
          ) {
            router.push("/");
          }
        }
        //if the current route from main pages [main layout]
        else if (
          !allowedRoutes.some(
            (route) => route.meta.permissions === currentRoute.meta.permissions
          ) &&
          currentRoute.name !== "Page404"
        ) {
          router.push("/");
        }
      });
      store.dispatch("setCountries");
    }
  },
  render: (h) => h(App),
}).$mount("#app");
