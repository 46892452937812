import { asyncRoutes} from "@/router/companyRoutes";
import companyLayout from "@/layout/companyLayout";
export const companiesRoutes = {
    path: "/companies",
    to: "/companies",
    icon: "mdi-account-multiple-outline",
    activeRoutes: ["record_company"],
    allow_children: false,
    showInMenu: true,
    name: "companies",
    meta: {
        title: "companies",
        permissions: "companies"
    },
    order: 5,
    component: () => import("@/views/companies"),
    children: [
        {
            path: "/",
            component: () => import("@/views/companies/record"),
            name: "record_company",
            icon: "mdi-file-document-multiple-outline",
            to: "/companies/record",
            allowed: true,
            meta: {
                title: "record_companies",
                permissions: "companies_view"
            }
        },
        {
            path: ":id",
            component: companyLayout ,
            name: "view_company",
            icon: "mdi-file-document-multiple-outline",
            allowed: true,
            redirect: "/companies/:id/show",
            children:[
                ...asyncRoutes
            ]
        },
        {
            path: ":id/edit",
            component: () => import("@/views/companies/actions"),
            name: "actions_company",
            icon: "mdi-square-edit-outline",
            allowed: true,
            meta: {
              title: "companies_edit",
              permissions: "companies_edit",
            },
          },
    ],
};
