export const featureGroupsRoutes = {
  path: "/feature-groups",
  to: "/feature-groups",
  icon: "mdi-table-of-contents",
  activeRoutes: ["record_feature-groups"],
  meta: {
    title: "feature-groups",
    permissions: "feature-groups"
  },
  showInMenu: true,
  allow_children: false,
  order : 12,
  component: () => import("@/views/feature-groups"),
  children: [
    {
      path: "/",
      component: () => import("@/views/feature-groups/record"),
      name: "record_feature-groups",
      icon: "mdi-file-document-multiple-outline",
      to: "/feature-groups/record",
      allowed: true,
      meta: {
        permissions: "feature-groups_view",
        title: "record_feature-groups"
      }
    },
    {
      path: "add",
      component: () => import("@/views/feature-groups/actions"),
      name: "add_permission",
      icon: "mdi-plus-circle-outline",
      allowed: true,
      meta: {
        permissions: "feature-groups_create"
      }
    },
    {
      path: ":id/edit",
      component: () => import("@/views/feature-groups/actions"),
      name: "edit_permission",
      icon: "mdi-square-edit-outline",
      hidden: true,
      meta: {
        permissions: "feature-groups_edit"
      }
    }
  ]
};
