import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import ar from "vuetify/lib/locale/ar";
import cookie from "js-cookie";
import store from "@/store";
Vue.use(Vuetify);

export default new Vuetify({
  rtl: true,

  theme: {
    dark: cookie && cookie["theme_mode"] === "dark",
    themes: {
      light: { ...store.getters["getTheme"] },
      dark: { ...store.getters["getTheme"] }
    }
  },
  lang: {
    current: "ar",
    locales: { ar }
  }
});
