<template>
  <v-row>
    <v-col cols="12">
      <v-label>
        <span class="font-bold form-label mb-4 primary--text">
          {{ $t(`dashboard.${label}`) }}</span
        >
      </v-label>
      <!-- @focus.prevent
        @copy.prevent
        @paste.prevent -->
      <v-text-field
        v-if="onlyNumber"
        @input="debounceData"
        @focus.prevent
       
        hide-details
        outlined
        v-bind="$attrs"
        v-model.trim="form[filterName]"
        @click:clear="debounceData"
        @keypress.enter="handleFilter"
        v-on="$listeners"
        ref="input_ref"
        oninput="this.value= this.value&&this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1').replace(/\+/g,'').replace(/\./g,'');"
      >
        <template #append>
          <slot />
        </template>
      </v-text-field>
      <v-text-field
        v-else-if="normalField"
        @input="debounceData"
        @focus.prevent
   
        hide-details
        outlined
        v-bind="$attrs"
        v-model.trim="form[filterName]"
        @click:clear="debounceData"
        @keypress.enter="handleFilter"
        v-on="$listeners"
      ></v-text-field>
      <v-text-field
        ref="input_ref"
        v-else-if="!onlyNumber"
        @input="debounceData"
        @focus.prevent
     
        hide-details
        outlined
        v-bind="$attrs"
        v-model.trim="form[filterName]"
        @click:clear="debounceData"
        @keypress.enter="handleFilter"
        v-on="$listeners"
      >
        <template #append>
          <slot />
        </template>
      </v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import { debounce } from 'lodash'
import GenericComponent from '../GenericComponent'

export default {
  name: 'TextInputFilter',
  extends: GenericComponent,
  props: {
    onlyNumber: {
      type: Boolean,
      default: false,
    },
    normalField: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      timStamp: null,
    }
  },
  methods: {
    debounceData: debounce(function () {
      if (!this.form[this.filterName]) {
        this.handleRemoveFilter()
      } else {
        this.handleFilter()
      }
    }, 500),
  },
}
</script>
