export const chargesRoutes = {
    path: "/charges",
    to: "/charges",
    icon: "mdi-table-of-contents",
    activeRoutes: ["record_charges"],
    meta: {
      title: "charges",
      permissions: "charges",
    },

    showInMenu: true,
    allow_children: false,
    order: 17,
    component: () => import("@/views/charges"),
    children: [
      {
        path: "/",
        component: () => import("@/views/charges/record/index.vue"),
        name: "record_charges",
        icon: "mdi-file-document-multiple-outline",
        to: "/charges/record",
        allowed: true,
        meta: {
          title: "record_charges",
          permissions: "charges_view",
        },
      },

      {
        path: ":id/show",
        component: () => import("@/views/charges/view/index.vue"),
        name: "add_permission",
        icon: "mdi-plus-circle-outline",
        allowed: true,
        meta: {
          title: "add_permission",
          permissions: "charges_create",
        },
      },
      
    ],
  };