export const messagesRoutes = {
  path: "/messages",
  to: "/messages",
  icon: "mdi-message",
  activeRoutes: ["record_messages"],
 
  meta: {
    title: "messages",
    permissions: "contact-messages",
  },
  showInMenu: true,
  allow_children: false,
  order : 6,
  component: () => import("@/views/messages"),
  children: [
    {
      path: "/",
      component: () => import("@/views/messages/record"),
      name: "record_messages",
      icon: "mdi-file-document-multiple-outline",
      to: "/messages/record",
      allowed: true,
      meta: {
        permissions: "contact-messages_view",
        title: "record_messages"
      }
    },
    
    {
      path: ":id/show",
      component: () => import("@/views/messages/view"),
      name: "message_message",
      icon: "mdi-square-edit-outline",
      hidden: true,
      meta: {
        permissions: "contact-messages_view",
        title: "message_message"
      }
    }
   
  ]
};
