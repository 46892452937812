const base = function () {
  if (process.env.NODE_ENV === "production") {
    return process.env.VUE_APP_ADMIN_PRODUCTION
  } else if (process.env.NODE_ENV === "dproduction") {
    return process.env.VUE_APP_ADMIN_DPRODUCTION;
  } else if (process.env.NODE_ENV === "tproduction") {
    return process.env.VUE_APP_ADMIN_TPRODUCTION;
  } else if (process.env.NODE_ENV === "dev") {
    return process.env.VUE_APP_ADMIN_DEV;
  } else if (process.env.NODE_ENV === "testing") {
    return process.env.VUE_APP_ADMIN_TEST;
  } else if (process.env.NODE_ENV === "beta") {
    return process.env.VUE_APP_ADMIN_BETA;
  }
  else {
    return process.env.VUE_APP_ADMIN_DEV;
  }
};
export const baseURL = base();

