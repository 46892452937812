export const orderRoutes = {
  path: "/orders",
  to: "/orders",
  icon: "mdi-invoice-list-outline",
  activeRoutes: ["record_order", "order_show"],
  allow_children: false,
  showInMenu: true,
  name: "order",
  meta: {
    title: "order",
    permissions: "orders",
  },
  order: 19,
  component: () => import("@/views/orders"),
  children: [
    {
      path: "/",
      component: () => import("@/views/orders/record"),
      name: "record_order",
      icon: "mdi-file-document-multiple-outline",
      to: "/orders/record",
      allowed: true,
      meta: {
        title: "record_order",
        permissions: "orders",
      },
    },

    {
      path: ":id/show",
      component: () => import("@/views/orders/view"),
      name: "order_show",
      icon: "mdi-square-show-outline",
      hidden: true,
      meta: {
        title: "order_show",
        permissions: "orders_view",
      },
    }
  ],
};
