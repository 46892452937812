export const countriesRoutes = {
  path: "/countries",
  to: "/countries",
  icon: "mdi-earth",
  activeRoutes: ["record_countries"],
  showInMenu: true,
  meta: {
    title: "countries",
    permissions: "countries"
  },
  order : 10,
  name : "countries",
  allow_children: false,
  component: () => import("@/views/countries"),
  children: [
    {
      path: "/",
      component: () => import("@/views/countries/record"),
      name: "record_countries",
      icon: "mdi-file-document-multiple-outline",
      to: "/countries/record",
      allowed: true,
      meta: {
        title: "record_countries",
        permissions: "countries_view"

      }
    },
    {
      path: ":id/edit",
      component: () => import("@/views/countries/actions"),
      name: "countries_edit",
      icon: "mdi-square-edit-outline",
      hidden: true,
      meta: {
        permissions: ["countries-fees_edit","countries/fees_view","countries-taxes_view","countries-taxes_edit"],
      },
    },
  ]
};
