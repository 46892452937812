export const companyRoutes = {
    path: "/companies/:id/show",
    to: "companies/:id/show",
    component: () => import("@/views/companies/view"),
    name: "view_company",
    icon: "mdi-file-document-multiple-outline",
    allowed: true,
    order : 14,
    activeRoutes: ["view_company"],
    meta: {
        permissions : "companies_view",
        title : "view_company",
        needInfoCompany:false
    }

};