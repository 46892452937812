const asyncRoutes = [];

// Define a mapping of route names to icons
const routeIconMap = {
  company_accounts: "mdi-briefcase-outline",
  addresses: "mdi-map-marker-outline",
  phones: "mdi-card-account-phone-outline",
  social:"mdi-card-account-mail-outline",
  company_bank_accounts: "mdi-bank-outline",
  view_company: "mdi-eye-outline",
  company_preferences: "mdi-cog-outline",
  contact_people_companies: "mdi-account-multiple-outline",
  contacts_companies: "mdi-phone-outline",
  company_entity: "mdi-office-building-outline",
  invitations_companies: "mdi-email-outline",
  invoices: "mdi-receipt-outline",
  company_license: "mdi-file-certificate-outline",
  company_personal: "mdi-account-outline",
};

// load all route files from companyModules folder
const context = require.context("./companyModules", false, /\.js$/);
context.keys().forEach((key) => {
  let fileName = key.replace(/(\.\/|\.js)/g, "");
  let routeModule = context(key)[fileName + "Routes"];

  // Check if the route exists and add the icon dynamically
  if (routeModule && routeModule.name) {
    routeModule.icon =
      routeIconMap[routeModule.name] || "mdi-help-circle-outline"; // Default icon if name is not in map
  }

  // files not exported by default
  console.log(context(key)[fileName + "Routes"]);
  // Add the route to asyncRoutes
  asyncRoutes.push(routeModule);
});

export { asyncRoutes };
