export const withdrawRoutes = {
  path: "/withdraw-requests",
  to: "/withdraw-requests",
  icon: "mdi-account-multiple-outline",
  activeRoutes: ["record_withdraw", "withdraw_show", "withdraw_edit"],
  allow_children: false,
  showInMenu: true,
  name: "withdraw",
  meta: {
    title: "withdraw",
    permissions: "withdraw-requests",
  },
  order: 16,
  component: () => import("@/views/withdraw-requests"),
  children: [
    {
      path: "/",
      component: () => import("@/views/withdraw-requests/record"),
      name: "record_withdraw",
      icon: "mdi-file-document-multiple-outline",
      to: "/withdraw-requests/record",
      allowed: true,
      meta: {
        title: "record_withdraw",
        permissions: "withdraw-requests_view",
      },
    },

    {
      path: ":id/show",
      component: () => import("@/views/withdraw-requests/view"),
      name: "withdraw_show",
      icon: "mdi-square-show-outline",
      hidden: true,
      meta: {
        title: "withdraw_show",
        permissions: "withdraw-requests_view",
      },
    },
    {
      path: ":id/edit",
      component: () => import("@/views/withdraw-requests/actions"),
      name: "withdraw_edit",
      icon: "mdi-square-edit-outline",
      hidden: true,
      meta: {
        permissions: "withdraw-requests_edit",
      },
    },
  ],
};
