export const themesRoutes = {
  path: "/themes",
  to: "/themes",
  icon: "mdi-palette",
  activeRoutes: ["record_themes", "edit_theme", "theme_theme"],
  meta: {
    title: "themes",
    permissions: "themes",
  },
  name : "themes",
  allow_children: false,
  showInMenu: true,
  order : 8,

  component: () => import("@/views/themes"),
  children: [
    {
      path: "/",
      component: () => import("@/views/themes/record"),
      name: "record_themes",
      icon: "mdi-file-document-multiple-outline",
      to: "/themes/record",
      allowed: true,
      meta: {
        title: "record_themes",
    permissions: "themes_view",

      },
    },

    {
      path: ":id/show",
      component: () => import("@/views/themes/view"),
      name: "theme_theme",
      icon: "mdi-square-edit-outline",
      hidden: true,
      meta: {
        title: "theme_theme",
    permissions: "themes_view",
      },
    },
    {
      path: ":id/edit",
      component: () => import("@/views/themes/actions"),
      name: "edit_theme",
      icon: "mdi-square-edit-outline",
      hidden: true,
      meta: {
        title: "themes_edit",
      },
    },
  ],
};
