<template>
  <div>
    <Preloader/>
    <SideBar />
    <router-view />
  </div>
</template>

<script>
export default {
  name: "DefaultLayout",
  components: { SideBar: () => import("@/components/SideBar") ,  Preloader: () => import("@/components/Preloader") }
};
</script>
