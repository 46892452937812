import Vue from "vue";
import "./shared";
import "./mixins";
import "./permission";
import "@/assets/scss/main.scss";
import "@/assets/fonts/stylesheet.css";
import "@/api";
import axios from "@/config/axios"; 
import ToggleService from "@/components/ToggleService";
Vue.component("toggle-service", ToggleService);
Vue.prototype.$eventBus = new Vue();
Vue.prototype.axios = axios
