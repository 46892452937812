export const usersRoutes = {
  path: "/users",
  to: "/users",
  icon: "mdi-account-multiple-outline",
  activeRoutes: ["record_userss", "add_user", "user_user"],
  allow_children: false,
  showInMenu: true,
  name: "users",
  meta: {
    title: "users",
    permissions: "users",
  },
  order: 1,
  component: () => import("@/views/users"),
  children: [
    {
      path: "/",
      component: () => import("@/views/users/record"),
      name: "record_users",
      icon: "mdi-file-document-multiple-outline",
      to: "/users/record",
      allowed: true,
      meta: {
        title: "record_users",
        permissions: "users_view",
      },
    },
    {
      path: "add",
      component: () => import("@/views/users/actions"),
      name: "add_user",
      hidden: true,
      icon: "mdi-plus-circle-outline",
      meta: {
        title: "users_create",
        permissions: "users_create",
      },
    },
    {
      path: ":id/show",
      component: () => import("@/views/users/view"),
      name: "show_user",
      icon: "mdi-square-show-outline",
      hidden: true,
      meta: {
        title: "users_show",
        permissions: "users_view",
      },
    },
    {
      path: ":id/edit",
      component: () => import("@/views/users/actions"),
      name: "edit_user",
      icon: "mdi-square-edit-outline",
      hidden: true,
      meta: {
        title: "users_edit",
        permissions: "users_edit",
      },
    },
  ],
};
