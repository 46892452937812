export const voucherRoutes = {
  path: "/vouchers",
  to: "/vouchers",
  icon: "mdi-account-cash",
  activeRoutes: ["record_voucher", "voucher_show"],
  allow_children: false,
  showInMenu: true,
  name: "voucher",
  meta: {
    title: "voucher",
    permissions: "vouchers",
  },
  order: 20,
  component: () => import("@/views/vouchers"),
  children: [
    {
      path: "/",
      component: () => import("@/views/vouchers/record"),
      name: "record_voucher",
      icon: "mdi-file-document-multiple-outline",
      to: "/vouchers/record",
      allowed: true,
      meta: {
        title: "record_voucher",
        permissions: "vouchers",
      },
    },

    {
      path: ":id/show",
      component: () => import("@/views/vouchers/view"),
      name: "voucher_show",
      icon: "mdi-square-show-outline",
      hidden: true,
      meta: {
        title: "voucher_show",
        permissions: "vouchers_view",
      },
    },
    {
      path: "add",
      component: () => import("@/views/vouchers/actions"),
      name: "add_voucher",
      hidden: true,
      icon: "mdi-plus-circle-outline",
      meta: {
        title: "vouchers_create",
        permissions: "vouchers_create",
      },
    },
  ],
};
