export const statesRoutes = {
  path: "/countries/:id/states",
  to: "/countries/:id/states",
  icon: "mdi-substates-outline",
  activeRoutes: ["record_states"],
  meta: {
    title: "states",
    permissions: "countries-states",
    hidden:true
  },
  showInMenu: false,
  name : "Country-States",
  allow_children: false,
  order : 11,
  component: () => import("@/views/states"),
  children: [
    {
      path: "/",
      component: () => import("@/views/states/record"),
      name: "record_states",
      icon: "mdi-file-document-multiple-outline",
      to: "/states/record",
      allowed: false,
      meta: {
        title: "record_states",
        permissions: "countries-states_view",


      }
    },
    {
      path: "/countries/:countryId/states/:stateId/edit",
      component: () => import("@/views/states/actions"),
      name: "edit_states",
      icon: "mdi-square-edit-outline",
      hidden: true,
      meta: {
        title: "edit_states",
        permissions: "countries-states_edit",
      }
    }
   
  ]
};
