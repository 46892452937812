export const mainInfoRoutes = {
    path: "/settings/main_informations/:id",
    to: "/settings/main_informations",
    component: () => import("@/views/new-settings/main-info/record"),
    icon: "offer.svg",
    allowed: true,
    order : 1,
    activeRoutes: ["mainInfo_records"],
    meta: {
        permissions : "settings_mainInfo",
        title : "main_informations_setting",
    }
  };