export const accountPermissionsRoutes = {
  path: "/account-permissions",
  to: "/account-permissions",
  icon: "mdi-shield-key-outline ",
  activeRoutes: ["record_account-permissions", "add_account-permission", "edit_account-permission"],
  meta: {
    title: "account-permissions",
    permissions: "account-permissions"
  },
  name: "account-permissions",
  allow_children: false,
  showInMenu: true,
  order: 3,
  component: () => import("@/views/account-permissions"),
  children: [
    {
      path: "/",
      component: () => import("@/views/account-permissions/record"),
      name: "record_permissions",
      icon: "mdi-file-document-multiple-outline",
      to: "/account-permissions/record",
      allowed: true,
      meta: {
        title: "record_permissions",
         permissions: "account-permissions_view"

      }
    },
    {
      path: ":id/edit",
      component: () => import("@/views/account-permissions/actions"),
      name: "edit_account-permission",
      icon: "mdi-square-edit-outline",
      hidden: true,
      meta: {
        title: "edit_account-permission",
        permissions: "account-permissions_edit"
      }
    }
  ]
};
