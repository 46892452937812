const company = {
  state: () => ({
    isEntity: false,
    isPersonal: false,
  }),
  mutations: {
    SET_IS_COMPANY(state, payload) {
      state.isEntity = payload.isEntity ?? false;
      state.isPersonal = payload.isPersonal ?? false;
    },
  },
  actions: {
    async fetchCompanyData({ commit }, companyId) {
      try {
        const res = await this._vm.$http.get({
          reqName: `companies/${companyId}`,
        });
        const { company } = res.data;

        // Check the company status if entity or personal or else
        if (company.entity && company.entity_company) {
          commit("SET_IS_COMPANY", { isEntity: true });
        } else if (!company.entity && company.personal_company) {
          commit("SET_IS_COMPANY", { isPersonal: true });
        } else {
          commit("SET_IS_COMPANY", { isPersonal: false, isEntity: false });
        }
      } catch (error) {
        console.error("Error fetching company data:", error);
      }
    },
  },
  getters: {
    getCompany: (state) => ({
      isEntity: state.isEntity,
      isPersonal: state.isPersonal,
    }),
  },
};

export default company;
