<template>
  <div class="mt-16 px-3 company-layout">
    <v-list dense class="d-flex flex-row justify-center tabs transparent py-0">
      <v-list-item-group
        v-model="selectedItem"
        color="primary"
        class="d-flex flex-row"
      >
        <div v-for="(item, i) in getCompanyRoutes" :key="i">
          <v-list-item
            :to="`/${item.to.split('/')[0]}/${$route.params.id}/${
              item.to.split('/')[2]
            }`"
            v-if="hasPermission(item.meta.permissions) &&
              (!item.meta.needInfoCompany ||
                (item.name == 'company_entity' && getCompany.isEntity) ||
                (item.name == 'company_personal' && getCompany.isPersonal))"
          >
            <v-icon v-html="item.icon" class="me-2 ms-1"> </v-icon>
            <v-list-item-content>
              <v-list-item-title
                class="tab-title me-1"
                v-text="$t(`topBar.${item.name}`)"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list-item-group>
    </v-list>

    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "DefaultLayout",
  data() {
    return {
      selectedItem: 2,
    };
  },

  computed: {
    ...mapGetters({
      getCompanyRoutes: "getCompanyRoutes",
      getCompany: "getCompany",
    }),
  },

  mounted() {
    this.$store.dispatch("fetchCompanyData", this.$route.params.id);
    console.log("CompanyRoutes: ", this.getCompanyRoutes);
  },
};
</script>
