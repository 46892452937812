export const licenseFeaturesRoutes = {
    path: "/licenses/:license_id/features",
    to: "/licenses/:license_id/features",
    icon: "mdi-substates-outline",
    activeRoutes: ["record_licenses_features"],
    meta: {
      title: "licenses_features",
      permissions: "licenses-features",
    },
    name: "license_features",
    allow_children: false,
    showInMenu: false,
    order : 15,
    component: () => import("@/views/license-features"),
    children: [
      {
        path: "/",
        component: () => import("@/views/license-features/record"),
        name: "record_license_features",
        icon: "mdi-file-document-multiple-outline",
        to: "/licences/:license_id/features",
        allowed: false,
        meta: {
          title: "record_license_features",
          permissions: "licenses-features_view",
        }
      },

      {
        path: "add",
        component: () => import("@/views/license-features/actions"),
        name: "add_license_features",
        hidden: true,
        allowed: false,
        meta: {
          title: "licenses-features_add",
          permissions: "licenses-features_add",
        },
      },
      {
        path: ":feature_id/show",
        component: () => import("@/views/license-features/view"),
        name: "show_license_features",
        hidden: true,
        allowed: false,
        meta: {
          title: "licenses-features_show",
          permissions: "licenses-features_view",
        },
      },
    ]
  };
  