export const licenseRoutes = {
    path: "/companies/:id/licence",
    to: "companies/:id/licence",
    icon: "mdi-account-multiple-outline",
    activeRoutes: [ "view_licence"],
    allow_children : false,
    showInMenu: true,
    name : "company_license",
    order : 23,
    meta: {
      title: "company_license",
      permissions: ["companies-license-renews_views","companies-license-changes_view","companies-license-recoveries_view"],
    },
    component: () => import("@/views/companies/license"),
    children: [
      {
        path: "/",
        component: () => import("@/views/companies/license/view"),
        name: "view_licence",
        icon: "mdi-file-document-multiple-outline",
        allowed: true,
        meta: {
          title: "view_license",
          permissions: "companies_view"
  
        }
    
        },
    ],
  };
  