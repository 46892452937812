<template>
  <ShimmerLoader type="table" :loading="loading">
    <transition name="fade" mode="out-in">
      <v-data-table
        @scroll="onScroll"
        v-if="toggleTable"
        :mobile-breakpoint="0"
        :sort-desc.sync="sortDesc"
        :headers="headers"
        :items="tableItems"
        :loading="loading"
        hide-default-footer
        class="card-shadow"
        :items-per-page="per_page"
        :no-data-text="$t('no_data')"
        :loading-text="$t('loading')"
        v-bind="$attrs"
        v-on="$listeners"
        item-key="id"
        ref="table_ref"
      >
        <!-- :custom-sort="customSort" -->
        <template #item="{ item, index, expand, isExpanded }">
          <tr>
            <slot
              :item="item"
              :index="index"
              :expand="expand"
              :is-expanded="isExpanded"
            ></slot>
          </tr>
        </template>
        <!-- <slot /> -->
        <template slot="no-data">
          {{ $t(`no_data`) }}
        </template>
        <template #expanded-item="{ item, headers }">
          <slot
            name="expend_row"
            :item="item"
            :headers="headers"
            :color="primary"
          />
        </template>
      </v-data-table>
    </transition>

    <v-row class="my-3 mb-10" v-if="showPagination">
      <v-col cols="12" class="align-self-center order-lg-2">
        <Pagination />
      </v-col>
    </v-row>
  </ShimmerLoader>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "CustomTable",
  inheritAttrs: false,
  props: {
    reqName: {
      type: String,
      default: "",
    },
    tableHeaders: {
      type: Array,
      default: () => [],
    },
    itemsData: {
      type: Array,
      default: () => [],
    },
    fromStore: {
      type: Boolean,
      default: true,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      headers: [],
      loading: true,
      sortDesc: true,
      toggleTable: true,
      disableSort: false,
      per_page: 20,
    };
  },
  computed: {
    ...mapGetters({
      items: "getsTableItems",
      pagination: "getPagination",
    }),
    tableItems() {
      if (this.fromStore) {
        return this.items;
      } else {
        return this.itemsData;
      }
    },
  },
  mounted() {
    this.$eventBus.$off();
    this.$eventBus.$on("on-refresh", () => {
      this.toggleTable = false;
      setTimeout(() => {
        this.toggleTable = true;
      }, 0);
    });
    this.$eventBus.$on("on-refetch-data", () => {
      this.getTableData();
    });
  },
  created() {
    this.createTableHeaders();
    if (this.reqName) {
      this.getTableData();
    }
  },
  methods: {
    ...mapActions({ getTableItems: "getTableItems" }),
    getTableData() {
      this.getTableItems({ reqName: this.reqName }).then(() => {
        this.loading = false;
      });
    },
    generateTableHeaders(headersName = []) {
      return headersName.map((header) => {
        return {
          text: header ? this.$t(`table.${header.name}`) : "",
          value: header.value,
          align: "start",
          sortable: header.sortable ? header.sortable : false,
        };
      });
    },
    createTableHeaders() {
      this.headers = this.generateTableHeaders(this.tableHeaders);
    },
    handleGetPerPage() {
      this.$store.dispatch("setQuery", {
        ...this.getQuery,
        per_page: this.per_page,
        page: 1,
      });
    },
    onScroll(e) {
      const { scrollTop, scrollHeight, clientHeight } = e.target;
      console.log(scrollTop, scrollHeight, clientHeight);
      
    },
  },
  watch: {
    getQuery: {
      handler(query) {
        if (query && Object.keys(query).length > 0) {
          this.getTableData();
        }
      },
      immediate: false,
    },
    reqName: {
      handler(value) {
        if (!value) this.loading = false;
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss">
.per-pages .v-input {
  grid-column: span 1;
}

.v-data-table {
  scrollbar-width: thin;
  // make scroll bar width 100px
  ::-webkit-scrollbar {
       width: 100px;
      height: 8px
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 40px;
  }
  ::-webkit-scrollbar-thumb {
    background: black;
    border-radius:40px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: black;
  }
}
</style>
