import axios from "@/config/axios";
const countries = {
  state: () => {
    return {
      countries: null,
    };
  },
  mutations: {
    SET_COUNTRIES(state, payload) {
      state.countries = payload;
    },
  },
  actions: {
    async setCountries({ commit }) {
      // Canot access direect to axios from here
      const res = await axios.get("countries", {
        params: { simple: true, pagination: "all" },
      });
      console.log(res , "countries");
      const countries = res.data.data;
      commit("SET_COUNTRIES", countries);
    },
  },
  getters: {
    getCountries: (state) => {
      return state.countries;
    },
  },
};

export default countries;
