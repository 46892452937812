<template>
  <v-select
    :label="$t('change_lang')"
    outlined
    v-model="locale"
    :items="items"
    item-text="name"
    item-value="value"
    @change="changeLocale"
    :menu-props="{ bottom: true, offsetY: true }"
  ></v-select>
</template>

<script>
import cookie from "js-cookie";
export default {
  name: "LocaleSelector",
  data() {
    return {
      locale: "en"
    };
  },

  computed: {
    items() {
      return [
        { name: this.$t("languages.en"), value: "en" },
        { name: this.$t("languages.ar"), value: "ar" }
      ];
    }
  },
  methods: {
    changeLocale() {
      this.$store.dispatch("setLocale", this.locale);
      this.$emit("on-change");
    }
  },

  destroyed() {
    const locale = cookie.get("language") || "en";
    this.$store.dispatch("setLocale", locale);
  }
  ,

  mounted(){
    this.locale=cookie.get("language") || "en";
  }
};
</script>
