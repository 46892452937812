export const couponsRoutes = {
    path: "/coupons",
    to: "/coupons",
    icon: "mdi-ticket-percent",
    activeRoutes: ["record_coupons"],
    meta: {
      title: "coupons",
      permissions: "coupons"
    },
    showInMenu: true,
    allow_children: false,
    order : 20,
    component: () => import("@/views/coupons"),
    children: [
      {
        path: "/",
        component: () => import("@/views/coupons/record"),
        name: "record_coupons",
        icon: "mdi-file-document-multiple-outline",
        to: "/coupons/record",
        allowed: true,
        meta: {
          permissions: "coupons_view",
          title: "record_coupons"
        }
      },
      {
        path: "add",
        component: () => import("@/views/coupons/actions"),
        name: "add_permission",
        icon: "mdi-plus-circle-outline",
        allowed: true,
        meta: {
          permissions: "coupons_create"
        }
      },
      {
        path: ":id/show",
        component: () => import("@/views/coupons/view"),
        name: "coupon_show",
        icon: "mdi-square-show-outline",
        hidden: true,
        meta: {
          title: "coupon_show",
          permissions: "coupons_view",
        },
      },
    ]
  };
  