export const addressesRoutes = {
    path: "/companies/:id/addresses",
    to: "companies/:id/addresses",
    icon: "mdi-account-multiple-outline",
    activeRoutes: [ "view_addresses"],
    allow_children : false,
    showInMenu: true,
    name : "addresses",
    order : 20,
    meta: {
      title: "company_addresses",
      permissions: "companies-addresses_view",
    },
    component: () => import("@/views/companies/addresses"),
    children: [
      {
        path: "/",
        component: () => import("@/views/companies/addresses/record"),
        name: "record_addresses",
        icon: "mdi-file-document-multiple-outline",
        allowed: true,
        meta: {
          title: "record_addresses",
          permissions: "companies_view"
  
        }
    
        },
      {
        path: ":addresses_id/show",
        component: () => import("@/views/companies/addresses/view"),
        name: "view_addresses",
        icon: "mdi-file-document-multiple-outline",
        allowed: true,
        meta: {
          title: "view_addresses",
          permissions: "companies_view"
  
        }
    
        },
    ],
  };
