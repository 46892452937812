export const preferencesRoutes = {
    path: "/companies/:id/preferences",
    to: "companies/:id/preferences",
    icon: "mdi-account-multiple-outline",
    activeRoutes: [ "view_preferences"],
    allow_children : false,
    showInMenu: true,
    name : "company_preferences",
    order : 24,
    meta: {
      title: "company_preferences",
      permissions: "companies-company-account-preferences_edit",
    },
    component: () => import("@/views/companies/preferences"),
    
  };
  