import vue from "vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import FormGroup from "@/components/FormGroup";
import ShimmerLoader from "@/components/ShimmerLoader";
import CustomTable from "@/components/CustomTable";
import Pagination from "@/components/Pagination";
import ToolTip from "@/components/ToolTip";
import Button from "@/components/Button";
import FiltersTextInput from "@/components/Filters/TextInput";
import FiltersDatePicker from "@/components/Filters/DatePicker";
import FiltersSelect from "@/components/Filters/Select";
import FormsTextInput from "@/components/Forms/TextInput";
import AutoComplete from "@/components/Forms/AutoComplete";
import DropDown from "@/components/Forms/DropDown";
import FormsTextArea from "@/components/Forms/TextArea";
import GenericDialog from "@/components/GenericDialog";
import LocaleSelector from "@/components/LocaleSelector";
import Editor from "@/components/Editor";
import CustomToggle from "@/components/CustomToggle";
import FormsColorPicker from "@/components/Forms/ColorPicker";
import PermissionGroup from "@/components/PermissionGroup";
import InputDate from "@/components/Input/Date";
import ButtonGroup from "@/components/ButtonGroup";
import CardInfo from "@/components/CardInfo";
import InputText from "@/components/Input/Text";
import InputPhone from "@/components/Input/Phone";


// components
vue.component("ValidationProvider", ValidationProvider);
vue.component("ValidationObserver", ValidationObserver);
vue.component("FormGroup", FormGroup);
vue.component("ToolTip", ToolTip);
vue.component("ShimmerLoader", ShimmerLoader);
vue.component("CustomTable", CustomTable);
vue.component("Pagination", Pagination);
vue.component("Button", Button);
vue.component("Button", Button);
vue.component("FiltersTextInput", FiltersTextInput);
vue.component("FiltersDatePicker", FiltersDatePicker);
vue.component("FiltersSelect", FiltersSelect);
vue.component("FormsTextInput", FormsTextInput);
vue.component("FormsColorPicker", FormsColorPicker);
vue.component("AutoComplete", AutoComplete);
vue.component("DropDown", DropDown);
vue.component("FormsTextArea", FormsTextArea);
vue.component("GenericDialog", GenericDialog);
vue.component("locale-selector", LocaleSelector);
vue.component("Editor", Editor);
vue.component("custom-toggle", CustomToggle);
vue.component("PermissionGroup", PermissionGroup);
vue.component("InputDate", InputDate);
vue.component("ButtonGroup", ButtonGroup);
vue.component("CardInfo", CardInfo);
vue.component("InputText", InputText);
vue.component("InputPhone", InputPhone);
