<template>
  <div class="input-phone">
    <v-label v-if="showLabel" :for="name">
      <strong class="font-weight-dark"> {{ $t(name) }}</strong>
    </v-label>
    <v-text-field
      :autofocus="focus"
      class="mt-1"
      v-bind="$attrs"
      :id="name"
      v-model="form[name]"
        :disabled="disabled"
      rounded
      flat
      outlined
      v-on="$listeners"
      type="number"
      :hint="showHint ? $t(`hint_${name}`) : ''"
      persistent-hint
      @keyup="clearServerErrors"
    >
      <template #append>
        <v-list-item class="justify-center px-0 country-dropdown">
          <v-menu
            rounded
            offset-y
            transition="slide-x-transition"
            max-height="300"
            @click.stop
          >
            <template #activator="{ attrs, on: { click } }">
              <Button
                :disabled="disabled"
                small
                depressed
                min-width="60"
                height="100%"
                v-bind="attrs"
                :loading="loading"
                v-on:click.stop="click"
                class="settings-phone"
              >
                <span v-if="selectedCountry && form.country_code">
                  <span style="direction: ltr">
                    ({{ `${form.country_code?.substring(2)}+` }})
                  </span>
                  <span left class="mx-1 font-flag">{{ selectedCountry.emoji_flag }}</span>
                </span>
                <v-icon right x-small> mdi-chevron-down </v-icon>
              </Button>
            </template>
            <v-list v-if="sharedCountries" class="pa-0">
              <v-list-item
                v-for="(country, index) in sharedCountries"
                :key="index"
                :active-class="
                  country.phone_code === form.country_code ? 'active-item' : ''
                "
                :input-value="country.code"
                @click.prevent="handleCountryCode(country)"
              >
                <v-list-item-title
                  class="d-flex align-center justify-center cursor-pointer"
                >
                  <span class="text-capitalize" style="direction: ltr">{{
                    `+${country.phone_code.substring(2)}`
                  }}</span>
                  <span left class="mx-1 font-flag">{{ country.emoji_flag }}</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item>
      </template>
    </v-text-field>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "InputTypePhone",
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    name: {
      type: String,
      default: () => "",
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    focus: {
      type: Boolean,
      default: () => false,
    },
    showLabel: {
      type: Boolean,
      default: () => true,
    },
    showHint: {
      type: Boolean,
      default: () => true,
    },
    fromProfile: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      countries: [],
      loading: true,
      selectedCountry: {},
    };
  },
  computed: {
    ...mapGetters({
      sharedCountries: "getCountries",
    }),
    ...mapGetters(["user"]),
  },
  watch: {
    sharedCountries: {
      handler(countries) {
        if (countries && countries.length > 0) {
          this.loading = false;
          if (!this.form.country_code) {
            if (this.form.code) {
              this.form.country_code = this.form.code;
            } else {
              this.form.country_code = "00966";
            }
          }

          this.selectedCountry = countries.find(
            (country) => country.phone_code === this.form.country_code
          );
        }
      },
      immediate: true,
      deep: true,
    },
    selectedCountry: {
      handler(country) {
        if (country) {
          this.form.country_code = country.phone_code;
        }
      },
      immediate: true,
    },
    user: {
      handler(user) {
        if (this.fromProfile) {
          this.form.country_code = user?.phone.country_code;
          this.form.number = user?.phone.number;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    ...mapActions(["clearServerErrors"]),
    handleCountryCode(country) {
      this.form.country_code = country.phone_code;
      this.selectedCountry = country;
    },
  },
};
</script>
