export const permissionsRoutes = {
  path: "/permissions",
  to: "/permissions",
  icon: "mdi-shield-key-outline ",
  activeRoutes: ["record_permissions", "add_permission", "edit_permission"],
  meta: {
    title: "permissions",
    permissions: "permissions"
  },
  allow_children: false,
  showInMenu: false,
 name : "permissions",
 order : 4,
  component: () => import("@/views/permissions"),
  children: [
    {
      path: "/",
      component: () => import("@/views/permissions/record"),
      name: "record_permissions",
      icon: "mdi-file-document-multiple-outline",
      to: "/permissions/record",
      allowed: true,
      meta: {
        title: "record_permissions",
     permissions: "permissions"

      }
    },
    {
      path: "add",
      component: () => import("@/views/permissions/actions"),
      name: "add_role",
      icon: "mdi-plus-circle-outline",
      to: "/permissions/add",
      allowed: true,
      meta: {
        title: "add_role"
      }
    },
    {
      path: ":id/edit",
      component: () => import("@/views/permissions/actions"),
      name: "edit_role",
      icon: "mdi-square-edit-outline",
      hidden: true,
      meta: {
        title: "edit_role"
      }
    }
  ]
};
