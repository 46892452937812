export const licensesRoutes = {
    path: "/licenses",
    to: "/licenses",
    icon: "mdi-license",
    activeRoutes: ["record_licenses"],
    meta: {
      title: "licenses",
      permissions: "licenses",
    },
    showInMenu: true,
    allow_children: false,
    order : 14,
    component: () => import("@/views/licenses"),
    children: [
      {
        path: "/",
        component: () => import("@/views/licenses/record"),
        name: "record_licenses",
        icon: "mdi-file-document-multiple-outline",
        to: "/licenses/record",
        allowed: true,
        meta: {
          permissions: "licenses_view",
          title: "record_licenses"
        }
      },
      
      {
        path: ":id/show",
        component: () => import("@/views/licenses/view"),
        name: "licenses_view",
        icon: "mdi-square-edit-outline",
        hidden: true,
        meta: {
          permissions: "licenses_view",
          title: "licenses_view"
        }
      }
     
    ]
  };
  