import request from "@/config/axios";

export function login({ password, email }) {
  const formData = new FormData();
  formData.append("email", email);
  formData.append("password", password);

  return request({
    url: "signin",
    method: "post",
    data: formData
  });
}
export function logout() {
  return request({
    url: "logout",
    method: "post"
  });
}
export function userInfo() {
  return request({
    url: "profile",
    method: "get"
  });
}
