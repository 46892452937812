import request from "@/config/axios";

export function updateToggle({ name, id, visible, locale, type }) {
  let data;
  if (type == 'visible') {
    data = { _method: "put", visible: visible, locale }
  } else if (type == 'closed') {
    data = { _method: "put", closed: visible, locale }
  } else if (type == 'approved') {
    data = { _method: "put", approved: visible, locale }
  }

  return request({
    url: `/${name}/${id}`,
    method: "post",
    data,
  });
}
