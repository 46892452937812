export const companiesEditRoutes = {
    path: "/companies-edit",
    to: "/companies-edit",
    icon: "mdi-account-multiple-outline",
    activeRoutes: ["record_companies-edit"],
    allow_children: false,
    showInMenu: true,
    name: "companies-edit",
    meta: {
      title: "companies-edit",
      permissions: "company-edits",
    },
    order: 21,
    component: () => import("@/views/companies-edit"),
    children: [
      {
        path: "/",
        component: () => import("@/views/companies-edit/record"),
        name: "company-edits_show",
        icon: "mdi-file-document-multiple-outline",
        to: "/companies-edit/record",
        allowed: true,
        meta: {
          title: "company-edits_show",
          permissions: "company-edits_view",
        },
      },
  
      {
        path: ":id/show",
        component: () => import("@/views/companies-edit/view"),
        name: "company-edits_show",
        icon: "mdi-square-show-outline",
        hidden: true,
        meta: {
          title: "company-edits_show",
          permissions: "company-edits_view",
        },
      },
      {
        path: ":id/edit",
        component: () => import("@/views/companies-edit/actions"),
        name: "edit_permission",
        icon: "mdi-square-edit-outline",
        hidden: true,
        meta: {
          permissions: "company-edits_edit"
        }
      }
    ],
  };
  