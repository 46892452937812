export const rolesRoutes = {
  path: "/roles",
  to: "/roles",
  icon: "mdi-account-multiple-outline",
  activeRoutes: ["record_roles", "add_roles", "roles"],
  meta: {
    title: "roles",
    permissions: "roles",
  },
  allow_children: false,
  showInMenu: true,
  name  : "roles",
  order : 2,
  component: () => import("@/views/roles"),
  children: [
    {
      path: "/",
      component: () => import("@/views/roles/record"),
      name: "record_roles",
      icon: "mdi-file-document-multiple-outline",
      to: "/roles/record",
      allowed: true,
      meta: {
        title: "record_roles",
    permissions: "roles_view",

      },
    },
    {
      path: "add",
      component: () => import("@/views/roles/actions"),
      name: "add_role",
      hidden: true,
      icon: "mdi-plus-circle-outline",
      meta: {
        title: "roles_create",
    permissions: "roles_create",

      },
    },
    {
      path: ":id/edit",
      component: () => import("@/views/roles/actions"),
      name: "edit_role",
      icon: "mdi-square-edit-outline",
      hidden: true,
      meta: {
        title: "roles_edit",
       permissions: "roles_edit",
      }
    },

  ],
};
