export const topicsRoutes = {
  path: "/topics",
  to: "/topics",
  icon: "mdi-table-of-contents",
  activeRoutes: ["record_topics"],
  meta: {
    title: "topics",
    permissions: "topics"
  },
  showInMenu: true,
  allow_children: false,
  order : 7,
  component: () => import("@/views/topics"),
  children: [
    {
      path: "/",
      component: () => import("@/views/topics/record"),
      name: "record_topics",
      icon: "mdi-file-document-multiple-outline",
      to: "/topics/record",
      allowed: true,
      meta: {
        permissions: "topics_view",
        title: "record_topics"
      }
    },
    {
      path: "add",
      component: () => import("@/views/topics/actions"),
      name: "add_permission",
      icon: "mdi-plus-circle-outline",
      allowed: true,
      meta: {
        permissions: "topics_create"
      }
    },
    {
      path: ":id/edit",
      component: () => import("@/views/topics/actions"),
      name: "edit_permission",
      icon: "mdi-square-edit-outline",
      hidden: true,
      meta: {
        permissions: "topics_edit"
      }
    }
  ]
};
