export const citiesRoutes = {
  path: "/countries/:country_id/states/:id/cities",
  to: "/countries/:country_id/states/:id/cities",
  icon: "mdi-substates-outline",
  activeRoutes: ["record_cities"],
  meta: {
    title: "cities",
    permissions: "countries-states-cities",
  },
  name: "country-states-cities",
  allow_children: false,
  showInMenu: false,
  order : 20,

  component: () => import("@/views/cities"),
  children: [
    {
      path: "/",
      component: () => import("@/views/cities/record"),
      name: "record_cities",
      icon: "mdi-file-document-multiple-outline",
      to: "/cities/record",
      allowed: false,
      meta: {
        title: "record_cities",
        permissions: "countries-states-cities_view",

      }
    },
    {
      path: "/countries/:countryId/states/:stateId/cities/:cityId/edit",
      component: () => import("@/views/cities/actions"),
      name: "edit_cities",
      icon: "mdi-square-edit-outline",
      hidden: true,
      meta: {
        title: "edit_cities",
        permissions: "countries-states-cities_edit",
      }
    }
   
  ]
};
