export const personalRoutes = {
    path: "/companies/:id/personal",
    to: "companies/:id/personal",
    icon: "mdi-account-multiple-outline",
    activeRoutes: [ "view_personal"],
    allow_children : false,
    showInMenu: true,
    name : "company_personal",
    order : 19,
    meta: {
      title: "company_personal",
      permissions: "companies-personal_view",
      needInfoCompany:true
    },
    component: () => import("@/views/companies/personal"),
    children: [
      {
        path: "/",
        component: () => import("@/views/companies/personal/view"),
        name: "view_personal",
        icon: "mdi-file-document-multiple-outline",
        allowed: true,
        meta: {
          title: "view_personal",
          permissions: "companies-personal_view"
  
        }
    
        },
    ],
  };
  