export const featuresRoutes = {
  path: "/features",
  to: "/features",
  icon: "mdi-table-of-contents",
  activeRoutes: ["record_features"],
  meta: {
    title: "features",
    permissions: "features"
  },
  showInMenu: true,
  allow_children: false,
  order : 13,
  component: () => import("@/views/features"),
  children: [
    {
      path: "/",
      component: () => import("@/views/features/record"),
      name: "record_features",
      icon: "mdi-file-document-multiple-outline",
      to: "/features/record",
      allowed: true,
      meta: {
        permissions: "features_view",
        title: "record_features"
      }
    },
    {
      path: "add",
      component: () => import("@/views/features/actions"),
      name: "add_permission",
      icon: "mdi-plus-circle-outline",
      allowed: true,
      meta: {
        permissions: "features_create"
      }
    },
    {
      path: ":id/edit",
      component: () => import("@/views/features/actions"),
      name: "edit_permission",
      icon: "mdi-square-edit-outline",
      hidden: true,
      meta: {
        permissions: "features_edit"
      }
    }
  ]
};
