import axios from "axios";
import store from "@/store";
import { baseURL } from "@/config/baseUrl";
import { getToken, removeToken } from "@/utils/auth";
import router from "../router";

import cookie from "js-cookie";

// create an axios instance
const service = axios.create({
  timeout: 60000, // request timeout
  baseURL: baseURL,
});

const getSystemTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone; 
};

// request interceptor
service.interceptors.request.use(
  async (config) => {
    // Do something before request is sent
    if (store.getters.token) {
      config.headers["Authorization"] = `${"Bearer " + getToken()}`;
    }
    const timezone = getSystemTimezone();
    config.headers["X-Locale"] = store.getters["getLocale"];
    config.headers["X-Timezone"] = timezone ;
    return config;
  },
  (error) => {
    // Do something with request error

    Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    // global success response message
    store.dispatch("ClearServerErrors");
    let { method } = response.config;
    if (method === "post" || method === "put" || method === "delete") {
      if (response.data.message) {
        store.dispatch("ShowNotification", {
          text: `${response.data.message}   `,
          color: "success",
        });
      }
    }
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch("ShowNotification", {
        text: error.response.data.message,
        color: "red",
      });
      removeToken();
      return router.push("/login");
    }
    if ([422, 409].includes(error.response.status)) {
      store.dispatch("ShowNotification", {
        text: error.response.data.message,
        color: "red",
      });
    }
    if (error.response.status === 403) {
      return router.push("/not-found");
    }
    // if (error.response.status === 404) {
    //   return router.push("/not-found");
    // }
    // global error response message
    if (!getToken()) {
      store.dispatch("ShowNotification", {
        text: error.response.data.message,
        color: "red",
      });
    }
    const { errors } = error.response.data;
    store.dispatch("SetServerErrors", errors);
    return Promise.reject(error);
  }
);

export default service;
